<!--
 * @Author: your name
 * @Date: 2021-05-13 14:25:04
 * @LastEditTime: 2021-05-19 14:58:56
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \one2\src\views\addskillselect\index.vue
-->
<template>
  <div class="allmsgclass">
    <navigation>
      <!-- <div class="addclass">
        <van-icon color="#F44C06" size=".38rem" name="add-o" />
        <div>选择新增</div>
      </div> -->
    </navigation>
    <div class="msgclass">请选择所要添加的技能类型</div>
    <div class="semsgcla">
      <input
        v-model="skillname"
        autofocus="autofocus"
        placeholder="请输入你要搜索的技能"
        type="text"
      />
      <van-icon
        @click="selectmeth"
        color="#FF4800"
        size=".38rem"
        name="search"
      />
    </div>
    <div class="jienng">
      <div
        @click="sskillmeth(item)"
        class="jindiv"
        v-for="(item, index) in allskillmsg"
        v-bind:key="index"
      >
        {{ item.position_name }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      skillname: "",
      allskillmsg: [], //技能列表
      allskillmsg2: [],
    };
  },
  created() {
    // this.positionId = this.$route.query.positionId;
    // this.getskilllist();
  },
  // watch:{
  //   skillname(msg){
  //     this.allskillmsg = this.allskillmsg2.filter(res=>{
  //       if((res.position_name+"").indexOf(msg)!=-1){
  //         return true;
  //       }else{
  //         return false;
  //       }
  //     })
  //   }
  // },
  methods: {
    //查询
    selectmeth() {
      this.$http.post("/firm/v1/Skillme/positionListSearch", {
        reqType: "userinfo",
        name: this.skillname,
      })
      .then(res=>{
         
        this.allskillmsg = JSON.parse(res.data).data;
      })
    },
    //点击技能名称
    sskillmeth(item) {
       
      this.setskill(item);
    },
    //添加技能
    setskill(item) {
      this.$http
        .post("/firm/v1/Skillme/userSkill", {
          reqType: "userinfo",
          phone: atob(this.$route.query.phone),
          skill_type:
            this.$route.query.position_name == "工作技能"
              ? "1"
              : this.$route.query.position_name == "生活技能"
              ? "2"
              : this.$route.query.position_name == "综合技能"
              ? "3"
              : "技能获取失败",
          skill_name: item.position_name,
          skill_id: item.positionId,
          mim_id: localStorage.getItem("userId"),
          status: "1",
        })
        .then((res) => {
           
          this.$toast("添加成功");
          this.$router.go(`${this.$route.query.isskill4?-4:-3}`);
        });
    },
    //获取技能云列表
    // getskilllist() {
    //   this.$http
    //     .post("/firm/v1/Skillme/positionList", {
    //       reqType: "userinfo",
    //       pid: this.positionId,
    //       name: "",
    //     })
    //     .then((res) => {
    //        
    //       let reses = JSON.parse(res.data).data;
    //       let res2 = reses[0].children.concat(reses[1].children);
    //       this.allskillmsg = reses[2].children.concat(res2);
    //       this.allskillmsg2 = this.allskillmsg;
    //     });
    // },
    clickskill(item) {
       
    },
  },
};
</script>
<style scoped>
.jindiv {
  margin-right: 0.2rem;
  padding: 0.15rem 0.69rem;
  border: 1px solid #979797;
  border-radius: 0.39rem;
  margin-bottom: 0.24rem;
  font-size: 0.28rem;
  line-height: 0.4rem;
  color: #979797;
}
.jienng {
  margin: 0.5rem 0.4rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.fenxiang {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  font-size: 0.3rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 0.42rem;
  padding: 0.29rem 0;
  background-color: #ffffff;
  border-top-color: #dfdfdf;
  border-top-style: solid;
  border-top-width: 1px;
}
.fenxiang button {
  background: linear-gradient(90deg, #ff4800 0%, #fc9e46 100%);
  border-radius: 0.16rem;
  border: none;
  padding: 0.28rem 0;
  text-align: center;
  width: 90%;
  margin-left: 5%;
}
.allmsgclass >>> .van-tree-select__nav-item {
  padding: 0.44rem 0.42rem;
}
.jirigc {
  font-size: 0.28rem;
  line-height: 0.48rem;
  color: #686868;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.jirigc > div {
  width: 50%;
  padding: 0.44rem 0;
  text-align: center;
}
.allmsgclass >>> .van-sidebar-item--select::before {
  background-color: #ffffff;
}
.semsgcla {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.29rem 0.48rem;
  padding: 0.11rem 0.32rem;
  border: 1px solid #ff4800;
  border-radius: 0.06rem;
}
.semsgcla input {
  border: none;
  font-size: 0.28rem;
  line-height: 0.48rem;
}
.msgclass {
  margin-top: 0.47rem;
  margin-left: 0.48rem;
  font-size: 0.42rem;
  line-height: 0.48rem;
  color: #333333;
  font-weight: bold;
}
.allmsgclass {
  background-color: #ffffff;
  min-height: 100%;
}
.addclass > :first-child {
  margin-right: 0.14rem;
}
.addclass {
  display: flex;
  align-items: center;
  font-size: 0.34rem;
  line-height: 0.48rem;
  color: #333333;
}
</style>
